* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
  }
  
  body {
	background: var(--color-bg);
  }

  a {
	  color: unset;
	  text-decoration: none;
  }

  .gradient__bg {

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

  }

  .gradient__text {
	  background:var(--gradient-text);
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
  }

  .gradient__text-titles {
	background:var(--gradient-text-titles);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }

  .section__padding {
	  padding: 4rem 6rem;
  }

  .section__margin {
	margin: 4rem 6rem;
  }

  .scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes scale-up-ver-top {
	0% {
	  -webkit-transform: scaleY(0.4);
			  transform: scaleY(0.4);
	  -webkit-transform-origin: 100% 0%;
			  transform-origin: 100% 0%;
	}
	100% {
	  -webkit-transform: scaleY(1);
			  transform: scaleY(1);
	  -webkit-transform-origin: 100% 0%;
			  transform-origin: 100% 0%;
	}
  }
  @keyframes scale-up-ver-top {
	0% {
	  -webkit-transform: scaleY(0.4);
			  transform: scaleY(0.4);
	  -webkit-transform-origin: 100% 0%;
			  transform-origin: 100% 0%;
	}
	100% {
	  -webkit-transform: scaleY(1);
			  transform: scaleY(1);
	  -webkit-transform-origin: 100% 0%;
			  transform-origin: 100% 0%;
	}
  }

  @media screen and (max-width: 700px) {
	.section__padding {
		padding: 4rem;
	}
  
	.section__margin {
	  margin: 4rem;
	}
  }

  @media screen and (max-width: 550px) {
	.section__padding {
		padding: 4rem 2rem;
	}
  
	.section__margin {
	  margin: 4rem 2rem;
	}
  }
  