.huge__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.huge__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.huge__brand-header {
    flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-right: 2rem;
}

.huge__brand-header h1 {
	font-family: var(--font-family);
    font-weight: 800;
    font-size: 42px;
    line-height: 55px;
    letter-spacing: -0.04em;
}

.huge__brand-footer {
    flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-left: 2rem;
	margin-right: 1rem;
}

.huge__brand-footer p {
	font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* letter-spacing: -0.04em; */
	color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .huge__brand-header h1 {
        font-size: 20px;
        line-height: 22px;
    }

    .huge__brand-footer p {
        font-size: 12px;
        line-height: 20px;
    }

    .huge__huge__brand {
		flex-direction: column;
        margin: 1rem 0;
    }
}