.img_logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.roadmap h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    align-items: flex-start;
    text-align: center;
    margin-left: 1rem;
}

.gradient__text_b {
    color: black;
    padding-bottom: 10px;
    padding-left: 20px;
}

.vertical-timeline-element-roadmap h3 {
    font-family: var(--font-family);
}

.vertical-timeline-element-roadmap ul {
    font-family: var(--font-family);
    /* color: var(--color-text); */
    color: black;
    padding-left: 20px;
}

@media screen and (max-width: 1250px) {
    .img_logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 870px) {
    .img_logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 770px) {
    .img_logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 550px) {
    .img_logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}